import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { DiscountUpsold, OrderWindow, UpsellDiscount, UpsellDiscountOrderWindow } from "Types";
import { useEventCallback, useLocalStorage } from "Hooks";
import { useOrderWindowsStore } from "Stores";
import _, { isEqual } from "lodash";
import { useCombinedDiscount } from "./useCombinedDiscount";

enum DISCOUNT_STORAGE {
    UPSELLDISCOUNTIDS
}

type HookReturnedProps = {
    upsoldDiscounts: UpsellDiscountOrderWindow[];
    forcedUpsellChecks: Map<string, boolean>;
    setForcedUpsellChecks: Dispatch<SetStateAction<Map<string, boolean>>>;
    controlSetForcedUpsellCheck: (discountId: string, disabled: boolean) => void;
    setUpsellDiscount: (discountId: string, productId: string) => void;
    upsoldDiscountsToCalculate: () => void;
    setSplitUpsoldDiscounts: (splitUpsoldDiscounts: UpsellDiscountOrderWindow | null) => void;
};

type HookProps = () => HookReturnedProps;

export const useUpsoldDiscounts: HookProps = () => {
    const {
        orderWindows,
        recalculateOrderWindows,
        activeOrderWindowId,
        splitUpsoldDiscounts,
        setSplitUpsoldDiscounts,
        comboDiscountIds,
        upsoldDiscounts,
        setUpsoldDiscounts
    } = useOrderWindowsStore();

    const { potentialUpsellDiscounts, allComboDiscounts } = useCombinedDiscount();

    const hasComboDiscounts = allComboDiscounts && allComboDiscounts.length > 0;
    const numberOfUpsellsPresent = potentialUpsellDiscounts.reduce((total: number, value: UpsellDiscount) => {
        return (total += value.potentialApplied?.size ?? 0);
    }, 0);

    const [forcedUpsellChecks, setForcedUpsellChecks] = useState<Map<string, boolean>>(new Map());

    const [storedUpsellDiscounts, setStoredUpsellDiscounts, removeStoredUpsellDiscounts] = useLocalStorage(
        DISCOUNT_STORAGE.UPSELLDISCOUNTIDS,
        []
    );

    /** Check marked discounts that have been upsold to what is discounted
     * - Possible that it could change, but also could change back so
     * - in local storage it keeps current upsold on order window until purchase gone through or empty cart
     */
    /**
     * Function to alter ongoing upsold discounts through the notification
     */
    const calculateUpsoldDiscounts = () => {
        let currentWindowUpsells: UpsellDiscountOrderWindow | null = null;
        if (upsoldDiscounts && upsoldDiscounts.length > 0) {
            currentWindowUpsells =
                upsoldDiscounts?.find(
                    (value: UpsellDiscountOrderWindow) => value?.orderWindowId === activeOrderWindowId
                ) ?? null;
        }

        if (currentWindowUpsells) {
            /** Discount could have been removed */
            const recalculateUpsoldDiscounts = currentWindowUpsells.upsoldDiscounts.reduce(
                (discountsUpsold: DiscountUpsold[], value: DiscountUpsold) => {
                    const discountInCombo = comboDiscountIds.get(value.discountId);
                    if (discountInCombo) {
                        let updatedUpsell = {} as DiscountUpsold;
                        if (
                            value.productIds.length === 0 &&
                            value.previousProductIds &&
                            !value.previousProductIds?.length
                        ) {
                            /** if another discount had taken over, then this discounts returned and was upsold
                             *  it moves the previous id back into the product ids
                             */
                            updatedUpsell = {
                                ...value,
                                productIds: [value.previousProductIds[0]]
                            };
                        } else if (discountInCombo < value.productIds.length) {
                            /** in a chance that the discount is rolled back,
                             * used to remove the product id that was selected
                             */
                            const lastProductId = value.productIds?.pop();
                            let ids: string[] = [];
                            if (lastProductId) {
                                ids = value.previousProductIds
                                    ? [lastProductId, ...value.previousProductIds]
                                    : [lastProductId];
                            }
                            updatedUpsell = {
                                ...value,
                                productIds: value.productIds,
                                previousProductIds: ids
                            };
                        } else if (discountInCombo > value.productIds.length) {
                            /** edge case
                             * if there is a decrease in quantity this triggers to make sure
                             * correct product ids are collected
                             */
                            const difference = discountInCombo - value.productIds.length;
                            const fromPrevious = value.previousProductIds.slice(0, difference).reverse();
                            updatedUpsell = {
                                ...value,
                                productIds: [...fromPrevious, ...value.productIds]
                            };
                        } else {
                            updatedUpsell = {
                                ...value,
                                productIds: value.productIds
                            };
                        }

                        discountsUpsold = [...discountsUpsold, updatedUpsell];
                    } else {
                        let updated = {} as DiscountUpsold;
                        if (value.productIds?.length === 0) {
                            updated = {
                                ...value
                            };
                        } else {
                            /** fluid nature of the combos - discounts can change as quantity increases
                             *  e.g so say 5 buns upsold and so was 3 buns, if it did get to 8 then
                             *  2 discounts would say an upsell
                             */
                            const lastProductId = value.productIds?.pop();
                            let ids: string[] = [];
                            if (lastProductId) {
                                ids = value.previousProductIds
                                    ? [lastProductId, ...value.previousProductIds]
                                    : [lastProductId];
                            }
                            updated = {
                                ...value,
                                productIds: value.productIds,
                                previousProductIds: ids
                            };
                        }
                        discountsUpsold = [...discountsUpsold, updated];
                    }
                    return discountsUpsold;
                },
                []
            );
            currentWindowUpsells = {
                ...currentWindowUpsells,
                upsoldDiscounts: recalculateUpsoldDiscounts
            };
        }

        if (currentWindowUpsells?.upsoldDiscounts.length) {
            const storageUpsell: any =
                upsoldDiscounts?.map((value: UpsellDiscountOrderWindow) =>
                    value.orderWindowId === activeOrderWindowId ? currentWindowUpsells : value
                ) ?? [];

            return storageUpsell;
        }
    };

    /**
     * Function if new OrderWindow
     * @param {string} discountId discount to be marked as upsold
     * @param {string} productId  product that created the upsell
     * @returns {UpsellDiscountOrderWindow} new upsell discount order window
     */
    const newOrderWindowUpsell = (discountId: string, productId: string) => {
        return {
            orderWindowId: activeOrderWindowId,
            upsoldDiscounts: [
                {
                    discountId: discountId,
                    productIds: [productId],
                    previousProductIds: []
                }
            ]
        };
    };

    /**
     *  Function to set upsold discount with product id that was used to create the combo discount
     * @param {string} discountId discount to be marked as upsold
     * @param {string} productId  product that created the upsell
     */
    const setUpsellDiscount = (discountId: string, productId: string) => {
        let upsellOrderWindows: UpsellDiscountOrderWindow[] = [];
        const upsellDiscountOrderWindows = upsoldDiscounts;

        if (upsellDiscountOrderWindows && upsellDiscountOrderWindows.length > 0) {
            const hasActiveOrderWindow = upsellDiscountOrderWindows.find(
                (value: UpsellDiscountOrderWindow) => value.orderWindowId === activeOrderWindowId
            );
            if (hasActiveOrderWindow) {
                upsellOrderWindows = upsellDiscountOrderWindows.map((value: UpsellDiscountOrderWindow) => {
                    if (value.orderWindowId === activeOrderWindowId) {
                        const hasDiscount = value.upsoldDiscounts.find(
                            (value: DiscountUpsold) => value.discountId === discountId
                        );
                        if (hasDiscount) {
                            const updatedDiscounts = value.upsoldDiscounts.map((discount: DiscountUpsold) => {
                                if (discount.discountId === discountId) {
                                    return {
                                        ...discount,
                                        productIds: [...discount.productIds, productId]
                                    };
                                } else {
                                    return discount;
                                }
                            });
                            return {
                                ...value,
                                upsoldDiscounts: updatedDiscounts
                            };
                        } else {
                            const newUpsoldDiscount = {
                                discountId: discountId,
                                productIds: [productId],
                                previousProductIds: []
                            };
                            return {
                                ...value,
                                upsoldDiscounts: [...value.upsoldDiscounts, newUpsoldDiscount]
                            } as UpsellDiscountOrderWindow;
                        }
                    } else {
                        return value as UpsellDiscountOrderWindow;
                    }
                });
            } else {
                upsellOrderWindows = [
                    ...upsellDiscountOrderWindows,
                    newOrderWindowUpsell(discountId, productId)
                ] as UpsellDiscountOrderWindow[];
            }
        } else {
            upsellOrderWindows = [newOrderWindowUpsell(discountId, productId)] as UpsellDiscountOrderWindow[];
        }
        setUpsoldDiscounts(upsellOrderWindows);
    };

    /**
     * Function to filter out non active order windows or reset state
     * @param {string[]} activeOrderWindowIds current selection of order window ids
     */
    const removeNonActiveOrderWindowsFromUpsell = (activeOrderWindowIds: string[]) => {
        const updatedUpsoldDiscount = upsoldDiscounts.filter((value: UpsellDiscountOrderWindow) => {
            return activeOrderWindowIds.includes(value.orderWindowId);
        });
        if (updatedUpsoldDiscount.length > 0) {
            setUpsoldDiscounts(updatedUpsoldDiscount);
        } else {
            setUpsoldDiscounts([]);
        }
    };

    /**
     * Controlling the forced upsell checks
     * @param {string} discountId upsell discount id
     * @param {boolean} disabled
     */
    const controlSetForcedUpsellCheck = (discountId: string, disabled: boolean) => {
        if (disabled) {
            forcedUpsellChecks.set(discountId, true);
        } else {
            if (forcedUpsellChecks.has(discountId)) {
                forcedUpsellChecks.delete(discountId);
                setForcedUpsellChecks(new Map([...forcedUpsellChecks]));
            }
        }
    };

    /**
     * Function to return the number of discounts in upsold
     * @returns {number} number of discounts in upsold discounts
     */
    const getUpsellDiscountCount = () => {
        return upsoldDiscounts.reduce((total: number, value: UpsellDiscountOrderWindow) => {
            return (total += value.upsoldDiscounts.length);
        }, 0);
    };

    /** Set state on first load - from locally stored upsold discount */
    useEffect(() => {
        if (storedUpsellDiscounts && storedUpsellDiscounts.length > 0) {
            setUpsoldDiscounts(storedUpsellDiscounts);
        }
    }, []);

    useEffect(() => {
        if (splitUpsoldDiscounts) {
            setUpsoldDiscounts(upsoldDiscounts.concat(splitUpsoldDiscounts));
        }
    }, [splitUpsoldDiscounts]);

    /** When combo discounts change / added / taken away to check if needed
     *  * Also takes care of forced upsell checks
     */
    useEffect(() => {
        if (upsoldDiscounts.length > 0 && hasComboDiscounts) {
            const newUpsoldDiscounts = calculateUpsoldDiscounts();
            if (!!newUpsoldDiscounts && !isEqual(upsoldDiscounts, newUpsoldDiscounts)) {
                setUpsoldDiscounts(newUpsoldDiscounts);
                recalculateOrderWindows();
            }
        }
        if (comboDiscountIds.size) {
            setForcedUpsellChecks(new Map());
        }
    }, [comboDiscountIds]);

    /** When no upsold make sure that the forced checks map is empty */
    useEffect(() => {
        if (!upsoldDiscounts.length) {
            setForcedUpsellChecks(new Map());
        } else {
            /**
             * Recalculate discounts on orderWindows
             * The check for orderWindows.length will ensure that we don't call setOrderWindows([]) right after refresh
             * If we do that, then we overwrite the persisted orderWindows and lose the ones we had before refresh
             *
             * This was primarily happening when you refreshed an OrderWindow with a combo discount applied
             */
            if (orderWindows.length) {
                recalculateOrderWindows();
            }
        }
    }, [upsoldDiscounts]);

    /** if there no upsells present and still remaining forced upsells removes
     * ** usually for only one combo
     *  ** edge if you increase to notification and then to combo and decrease by incrementing it
     * ** this will reset it
     */
    useEffect(() => {
        if (hasComboDiscounts && numberOfUpsellsPresent === 0 && forcedUpsellChecks.size > 0) {
            setForcedUpsellChecks(new Map());
        }
    }, [numberOfUpsellsPresent]);

    /** keep upsold discounts if page refreshes */
    useEventCallback({
        eventName: "beforeunload",
        callback: (_: BeforeUnloadEvent) => {
            const upsellDiscountCount = getUpsellDiscountCount();
            if (upsoldDiscounts.length === 0 || upsellDiscountCount === 0) {
                removeStoredUpsellDiscounts();
            } else {
                setStoredUpsellDiscounts(upsoldDiscounts);
            }
        }
    });

    return {
        upsoldDiscounts,
        forcedUpsellChecks,
        setForcedUpsellChecks,
        controlSetForcedUpsellCheck,
        setUpsellDiscount,
        upsoldDiscountsToCalculate: calculateUpsoldDiscounts,
        setSplitUpsoldDiscounts
    };
};
