import { useEffect } from "react";
import { floor, cloneDeep, flatMap, isEqual } from "lodash";

import { usePos } from "Providers";
import {
    OrderWindow,
    CartProduct,
    ComboDiscount,
    DiscountCheck,
    PotentialComboDiscount,
    SelectedCartProduct,
    UpsellDiscount,
    Menu,
    MenuBundleProduct,
    MenuProduct
} from "Types";
import {
    howToApplyDiscountByCartIdAndQuantity,
    getTotalDiscountedPrice,
    updateSelectedProductQuantities,
    countCartIdsInDiscounts
} from "./comboDiscountHelper";
import { getCartProductOrgUnitPrice } from "../../../admin/components/poses/pos/utils";
import { useOrderWindowsStore } from "Stores";

type CombinedDiscountHookReturn = {
    comboDiscountIds: Map<string, number>;
    potentialComboDiscounts: PotentialComboDiscount[];
    potentialUpsellDiscounts: UpsellDiscount[];
    allComboDiscounts: ComboDiscount[];
    setComboDiscounts: (comboDiscount: ComboDiscount[]) => void;
    clearDiscounts: () => void;
};

/** Initial calculations which are the best discounts with the order of price and quantity
 *  in the hook it returns how many times a particular discount can be applied to the cart
 *  This is all based on quantities and max per order - this information is then sent to the the Helper
 *  to calculate the prices etc.
 *  NOTE: should always pick the best discounts first
 * @param {string} activeOrderWindowId current active order window Id
 */
export const useCombinedDiscount = (): CombinedDiscountHookReturn => {
    const { menus } = usePos();
    const {
        getActiveOrderWindow,
        recalculateOrderWindows,
        potentialComboDiscounts,
        setPotentialComboDiscounts,
        comboDiscountIds,
        setComboDiscountIds,
        allComboDiscounts,
        setAllComboDiscounts: onSetAllComboDiscounts,
        potentialUpsellDiscounts,
        setPotentialUpsellDiscounts
    } = useOrderWindowsStore();
    const orderWindow = getActiveOrderWindow();

    /**
     * Function to calculate the actual discount value per discount with possible quantities
     * @param {PotentialComboDiscount} discount Current discount to check
     * @param {number} canBeApplied Number of times the discount could be applied according to the cart and limits!
     * @param {boolean} freezeQuantities if true this will freeze the quantites within this function useful for
     * finding the best discounts - true is used as the first application to find the starting point
     * or false for the actual discounts that could be applied (so it updates quantities)
     * @returns {DiscountCheck} contains discount value and quantity used
     */
    const canDiscountBeApplied = (
        discount: PotentialComboDiscount,
        canBeApplied: number,
        freezeQuantities: boolean = true
    ) => {
        let freezeDiscount: any = freezeQuantities ? cloneDeep(discount) : discount;

        let discountCanBeApplied: boolean = true;
        if (!freezeQuantities) {
            const noProductsHaveBeenDiscounted = discount.selectedCartProducts?.every(
                (select: SelectedCartProduct) => select.currentQuantity === select.originalQuantity
            );
            if (!noProductsHaveBeenDiscounted) {
                discountCanBeApplied = discount.canCombine ?? false;
            }
        }

        let discountSummary: DiscountCheck = {
            id: freezeDiscount.id!,
            discountValue: 0,
            quantityPerDiscount: freezeDiscount.comboCategories[0].limit,
            discountApplied: 0,
            quantityLeft: 0,
            cartIdsUsed: new Map(),
            cartIdsNotUsed: []
        };

        if (!discountCanBeApplied || !orderWindow) {
            return discountSummary;
        }

        const { cartProducts } = orderWindow as OrderWindow;

        [...Array(canBeApplied)].forEach(() => {
            const [applied, quantityLeft] = howToApplyDiscountByCartIdAndQuantity(freezeDiscount);
            const value = getTotalDiscountedPrice(applied, cartProducts);

            const discountValue = value - freezeDiscount.staticPrice;
            if (discountValue > 0) {
                discountSummary.discountValue += discountValue;
                discountSummary.discountApplied++;
            }

            applied.forEach((value: number, id: string) => {
                const alreadyApplied = discountSummary.cartIdsUsed?.get(id) ?? 0;
                discountSummary.cartIdsUsed?.set(id, alreadyApplied + value);
                discountSummary.quantityLeft += quantityLeft;
            });

            // finally if the curent application of the discount is a negative it will make sure the quantity is back to when the discount was applied
            // just so that any other discounts could be applied e.g say you have 4 item product discount and a 2 item with a cart of 10 items
            // It could check if 4 could be applied 3 times - however it can't so it makes sure that it can be 4 x 2 times then the 2 item discount
            if (applied.size > 0 && discountValue <= 0 && !freezeQuantities) {
                freezeDiscount?.selectedCartProducts.forEach((product: SelectedCartProduct) => {
                    const cartValue = applied.get(product.cartId);
                    if (cartValue) {
                        product.currentQuantity += cartValue;
                    }
                });
            }
        });

        /** This is an outlier - should probably never be used, looks at high discount values, which could effect quantities against the static price */
        if (discountSummary.quantityLeft > 0 && !freezeQuantities) {
            const quantityUsed = Array.from(discountSummary?.cartIdsUsed ?? []).reduce(
                (total: number, usedId: [string, number]) => {
                    const [cartId, applied] = usedId;
                    return (total += applied);
                },
                0
            );
            const quantityShouldBe = discountSummary.discountApplied * discountSummary.quantityPerDiscount;
            const quantityDifference = quantityUsed - quantityShouldBe;
            if (quantityDifference < 0) {
                discountSummary.discountApplied -= 1;
            }
        }
        return discountSummary;
    };

    /**
     * Function will check the limit for a discount - how many times it can be discounted per order
     * @param {PotentialComboDiscount} discount current discount to check for quantity
     * @returns {number} quantity possible for this discount against the cart quantity
     */
    const potentialPossibleQuantity = (discount: PotentialComboDiscount) => {
        const discountQuantity = discount.comboCategories[0].limit;
        const maxPerPurchase = discount.comboCategories[0].maxPerPurchase ?? 0;
        let quantityPossible = discountQuantity;

        if (discount.selectedCartProducts) {
            if (maxPerPurchase !== 0) {
                const potentialQuantityWithLimit = discountQuantity * discount.canApply;
                const maxQuantityWithPurchase = discountQuantity * maxPerPurchase;
                if (maxQuantityWithPurchase > potentialQuantityWithLimit) {
                    quantityPossible = potentialQuantityWithLimit;
                } else {
                    quantityPossible = maxQuantityWithPurchase;
                }
            } else {
                quantityPossible = discountQuantity * discount.canApply;
            }
        }
        return quantityPossible;
    };

    /**
     * function to find best discount order from the potential discounts
     * @param {PotentialComboDiscount[]} freezedDiscounts you can pass in potential discounts instead of using the main potential discount
     * NOTE: this is for two separate places - 1. first time uses the main potential discounts to find the starting point 2. fluid nature of finding
     *      the best discounts as the quantity changes
     * @returns {Map<string, number>} map of best discounts in order or best value
     */
    const checkBestDiscountOrder = (freezedDiscounts: PotentialComboDiscount[] = []) => {
        let freezePotential =
            freezedDiscounts.length > 0 ? cloneDeep(freezedDiscounts) : cloneDeep(potentialComboDiscounts);
        const bestOrder = freezePotential.reduce(
            (bestDiscounts: Map<string, number>, discount: PotentialComboDiscount) => {
                const discountQuantity = discount.comboCategories[0].limit;
                let quantityPossible = potentialPossibleQuantity(discount);
                const discountCouldBeApplied = quantityPossible / discountQuantity;
                const discountSummary = canDiscountBeApplied(discount, discountCouldBeApplied);
                return bestDiscounts.set(discount.id!, discountSummary.discountValue);
            },
            new Map<string, number>()
        );
        return new Map([...bestOrder.entries()].sort((idA, idB) => idB[1] - idA[1]));
    };

    /**
     * Function to set which discounts to apply and number of times to apply
     * NOTE: will always start with the best discount value first and then repeats until there are no discounts left
     *
     * Loops number of potential discounts not the actual discounts as this is fluid and best discount values can change after quantity changes
     * each loop checks the best discount from the remaining quantities
     */
    const calculateDiscountIdsAndNumberOfTimesApplied = (potentialComboDiscounts: PotentialComboDiscount[]) => {
        let alreadyDiscountedIds = new Set<string>();
        let discountApplied = new Map<string, number>();
        let freezePotential = cloneDeep(potentialComboDiscounts);
        [...Array(freezePotential.length)].forEach((_, __) => {
            const getBestDiscount = checkBestDiscountOrder(freezePotential);
            const bestDiscountId = getBestDiscount.entries().next().value[0];
            const discount = freezePotential.find(
                (potential: PotentialComboDiscount) => potential.id === bestDiscountId
            )!;
            const discountQuantity = discount.comboCategories[0].limit;
            let quantityPossible = potentialPossibleQuantity(discount);
            const discountCouldBeApplied = quantityPossible / discountQuantity;
            const summary = canDiscountBeApplied(discount, discountCouldBeApplied, false);
            if (summary.discountApplied > 0) {
                discountApplied.set(discount.id!, summary.discountApplied);
                discount.canApply = 0;
                // updates cart quantities that are on other discounts
                freezePotential = updateSelectedProductQuantities(freezePotential, discount, alreadyDiscountedIds);
            }
            alreadyDiscountedIds.add(discount.id!);
        });
        return discountApplied;
    };

    /**
     * Function to set potential discounts - not always what will be applied
     * SelectCartProduct in each potential discount - sets the org unit price and quantities of the cart product
     * Sorts the array by highest price and then number of times it appears in other discounts
     */
    const calculatePotentialComboDiscounts = () => {
        let cartToComboDiscount: PotentialComboDiscount[] = [];
        // Set the selectedCartProduct array with quantity in cart and org unit price (needed to calculate best discounts)
        allComboDiscounts.forEach((discount: ComboDiscount) => {
            const { comboCategories } = discount;

            let cartQuantity: number = 0;
            let cartProducts: SelectedCartProduct[] = [];
            orderWindow?.cartProducts.forEach((cart: CartProduct) => {
                const { orderProduct } = cart;
                const getPrice = getCartProductOrgUnitPrice(cart);
                const productId = orderProduct.refProductId || orderProduct.refBundleProductId;
                const productInCombo = comboCategories[0].menuProductIds.includes(productId ?? "");
                if (productInCombo) {
                    cartQuantity += orderProduct.quantity;
                    const selected: SelectedCartProduct = {
                        cartId: cart.id,
                        orgUnitPrice: getPrice ?? 0,
                        currentQuantity: orderProduct.quantity,
                        originalQuantity: orderProduct.quantity,
                        name: orderProduct.name
                    };
                    cartProducts.push(selected);
                }
            });

            if (cartQuantity >= comboCategories[0].limit) {
                const potential: PotentialComboDiscount = {
                    ...discount,
                    canApply: floor(cartQuantity / comboCategories[0].limit, 0),
                    selectedCartProducts: cartProducts
                };
                cartToComboDiscount.push(potential);
            }
        });
        // Here it sorts the cart products in potential discount by price and then number of times it appears in all discounts!
        const potential = sortPotentialDiscountProducts(cartToComboDiscount);
        return potential;
    };

    /**
     * Function to sort potential discount by price/ quantity and number of time in a discount
     * @param {PotentialComboDiscount[]} potentialDiscounts
     * @returns
     */
    const sortPotentialDiscountProducts = (potentialDiscounts: PotentialComboDiscount[]) => {
        return potentialDiscounts.map((cart: PotentialComboDiscount) => {
            const sortCarProducts = sortSelectedCartProducts(cart.selectedCartProducts ?? [], potentialDiscounts);
            return {
                ...cart,
                selectedCartProducts: sortCarProducts
            };
        });
    };

    /**
     * Function to sort selected cart products on discount by price / quantity and number times in potential discounts
     * @param {SelectedCartProduct[]} selectedCartProducts
     * @param {PotentialComboDiscount[]} potentialDiscounts
     * @returns {SelectedCartProduct[]}
     */
    const sortSelectedCartProducts = (
        selectedCartProducts: SelectedCartProduct[],
        potentialDiscounts: PotentialComboDiscount[]
    ) => {
        return selectedCartProducts?.sort(
            (a: SelectedCartProduct, b: SelectedCartProduct) =>
                b.orgUnitPrice - a.orgUnitPrice ||
                b.currentQuantity - a.currentQuantity ||
                countCartIdsInDiscounts(potentialDiscounts, b.cartId) -
                    countCartIdsInDiscounts(potentialDiscounts, a.cartId)
        );
    };

    /**
     * Function to get potential discount from a cart product, will also use other cart products with the same product id
     * 1. Adds selected products to the discount so that the calculation of the discount can be done
     * 2. Increases current cart product quantity by one for checking if the discount is next
     * @param {ComboDiscount} discount checking current discount
     * @param {string} currentCartId current cart id to check
     * @returns {PotentialComboDiscount | null} potential discount with selceted products
     */
    const potentialComboDiscount = (discount: ComboDiscount, currentCartId: string) => {
        const { comboCategories } = discount;
        const cloneCartProducts = cloneDeep(orderWindow?.cartProducts);
        let cartQuantity: number = 0;
        let cartProducts: SelectedCartProduct[] = [];

        /** Selected cart products per discount */
        cloneCartProducts?.forEach((cart: CartProduct) => {
            const { orderProduct } = cart;
            const getPrice = getCartProductOrgUnitPrice(cart);
            const productId = orderProduct.refProductId || orderProduct.refBundleProductId;
            const productInCombo = comboCategories[0].menuProductIds.includes(productId ?? "");
            if (productInCombo) {
                cartQuantity += orderProduct.quantity;
                const selected: SelectedCartProduct = {
                    cartId: cart.id,
                    orgUnitPrice: getPrice ?? 0,
                    currentQuantity: orderProduct.quantity,
                    originalQuantity: orderProduct.quantity,
                    name: orderProduct.name,
                    productId: productId
                };
                cartProducts.push(selected);
            }
        });

        if (cartProducts?.length === 0) {
            return null;
        }

        /** Add to discount the increase of quantity to the cart products used in a particular discount
         *  Used in a further function to calculate the discounts potential to be applied
         */
        const increaseQuantity = cartProducts.map((selected: SelectedCartProduct) => {
            if (selected.cartId === currentCartId) {
                return {
                    ...selected,
                    currentQuantity: selected.currentQuantity + 1,
                    originalQuantity: selected?.currentQuantity + 1
                };
            }
            return selected;
        });

        cartQuantity += 1;

        /** Add the number of times the discount can be applied - this is used in the calculations for discount values */
        if (cartQuantity >= comboCategories[0].limit) {
            return {
                ...discount,
                canApply: floor(cartQuantity / comboCategories[0].limit, 0),
                selectedCartProducts: increaseQuantity
            };
        }
        return null;
    };

    /**
     * Function to find upcoming discounts by using each individual cart quantity increase
     * @param {UpsellDiscount[]} cartIdsAndQuantity basic upsell discount array
     * @returns {UpsellDiscount[]} full upsell discount array
     */
    const bestUpComingDiscount = (cartIdsAndQuantity: UpsellDiscount[]) => {
        let bestUpcomingDiscounts: UpsellDiscount[] = [];
        /** Calculate the discounts on each cart product with how many times the discount can be applied
         *  Which will be used in the notification component to upsell 2 x discount etc.
         *  Also adds the menu / bundle product to the upsell
         */
        cartIdsAndQuantity.forEach((value: UpsellDiscount) => {
            const theClonedValue = cloneDeep(value);
            if (theClonedValue.discounts) {
                const { discounts } = theClonedValue;
                /** Reusing the same function for actual combo discounts - to make sure that the same discounts will be applied
                 * The discounts added to the object are all discounts that are applied if you increase this cart product quantity
                 * E.g it will include any discount that has been already applied that is not related to this cart product
                 */
                const appliedDiscounts = calculateDiscountIdsAndNumberOfTimesApplied(theClonedValue.discounts);

                /**
                 * According to the comment above, we don't want to do set `setComboDiscountIds(appliedDiscounts)`
                 *  because that state applies across orderWindows
                 */

                const singleDiscount = discounts.filter((value: PotentialComboDiscount) =>
                    appliedDiscounts.has(value.id!)
                );
                if (singleDiscount) {
                    /** modal can be opened from the notification with multiple products in the discount
                     *  menu product / menu bundle products are added to make it easier to add to the cart
                     */
                    const refProductsDiscounts = getUpsellRefProductsOnDiscount(singleDiscount);
                    const potentialDiscount: UpsellDiscount = {
                        ...value,
                        discounts: refProductsDiscounts,
                        potentialApplied: appliedDiscounts // all discounts that will be applied if the cart product increases (even discounts not related)
                    };
                    bestUpcomingDiscounts = [...bestUpcomingDiscounts, potentialDiscount];
                }
            }
        });

        return bestUpcomingDiscounts;
    };
    /**
     * Function to create first step upsell discount array with potential discounts
     * @param {OrderWindow} orderWindow
     * @returns {UpsellDiscount[]}
     */
    const getUpsellProductsAndDiscounts = (orderWindow: OrderWindow | undefined) => {
        const upsellDiscounts: UpsellDiscount[] =
            orderWindow?.cartProducts.map((cart: CartProduct) => {
                const { orderProduct, id } = cart;
                const productId = orderProduct.refProductId || orderProduct.refBundleProductId;
                const cartIdWithPotentialDiscounts = allComboDiscounts.reduce(
                    (cartId: Map<string, PotentialComboDiscount[]>, discount: ComboDiscount) => {
                        const thePotentialDiscount = potentialComboDiscount(discount, id);
                        if (thePotentialDiscount) {
                            const getMap = cartId.get(id);
                            if (getMap) {
                                cartId.set(id, [...getMap, thePotentialDiscount]);
                            } else {
                                cartId.set(id, [thePotentialDiscount]);
                            }
                        }
                        return cartId;
                    },
                    new Map()
                );
                const potentialDiscounts = cartIdWithPotentialDiscounts.get(id);
                const sortedDiscounts = sortPotentialDiscountProducts(potentialDiscounts ?? []);
                return {
                    cartId: id,
                    productName: orderProduct.name,
                    quantity: orderProduct.quantity,
                    productId: productId!,
                    discounts: sortedDiscounts
                };
            }) ?? [];
        return upsellDiscounts;
    };

    /**
     * Function to set possible upsell discounts
     * @param {OrderWindow} orderWindow
     */
    const setPossibleUpsellDiscounts = (orderWindow: OrderWindow | undefined) => {
        /** Set all cart products into an upsell discount array */
        const potentialUpsellCart = getUpsellProductsAndDiscounts(orderWindow);
        const potentiallyBestUpsells = bestUpComingDiscount(potentialUpsellCart);
        setPotentialUpsellDiscounts(potentiallyBestUpsells);
    };

    const setThePotentialComboDiscounts = () => {
        let newPotentialComboDiscounts: PotentialComboDiscount[] = [];
        if (!!orderWindow?.cartProducts.length) {
            newPotentialComboDiscounts = calculatePotentialComboDiscounts();
        }

        if (!isEqual(potentialComboDiscounts, newPotentialComboDiscounts)) {
            setPotentialComboDiscounts(newPotentialComboDiscounts);
            recalculateOrderWindows();
        }
    };

    /**
     * Function to menu ref Products to the discounts - makes things easier for the modal when muliple products on a discount
     * @param {PotentialComboDiscount[]} discounts to be used to add menu ref products to the upsell array
     * @returns
     */
    const getUpsellRefProductsOnDiscount = (discounts: PotentialComboDiscount[]): PotentialComboDiscount[] => {
        const menuProductCategories = flatMap(menus.map((menu: Menu) => menu.menuProductCategories));
        const menuProducts = flatMap(
            menuProductCategories.map((category: any) => {
                return category.menuProducts.length ? category.menuProducts : category.menuBundleProducts;
            })
        );

        const distinctMenuRefProducts = [
            ...menuProducts
                .reduce((products: Map<string, MenuProduct | MenuBundleProduct>, value: any) => {
                    const product = value.refProduct || value.refBundleProduct;
                    products.set(product.id, value);
                    return products;
                }, new Map())
                .values()
        ];

        return discounts.reduce((updatedDiscounts: PotentialComboDiscount[], value: PotentialComboDiscount) => {
            const menuProductIds = value.comboCategories[0].menuProductIds;
            const updatedUpsellMenuProducts = distinctMenuRefProducts.filter((product: any) => {
                const refProduct = product.refProduct || product.refBundleProduct;
                return menuProductIds.includes(refProduct.id);
            });
            const updatedDiscount: PotentialComboDiscount = {
                ...value,
                upsellMenuProducts: updatedUpsellMenuProducts
            };
            return [...updatedDiscounts, updatedDiscount];
        }, []);
    };

    useEffect(() => {
        setThePotentialComboDiscounts();
    }, [orderWindow?.cartProducts, orderWindow?.cartProducts?.length]);

    useEffect(() => {
        if (!orderWindow) {
            setPotentialComboDiscounts([]);
        }
    }, [orderWindow]);

    useEffect(() => {
        if (orderWindow?.cartProducts) {
            setThePotentialComboDiscounts();
        }
    }, [allComboDiscounts]);

    useEffect(() => {
        let newComboDiscountIds = new Map();
        if (potentialComboDiscounts && potentialComboDiscounts.length) {
            newComboDiscountIds = calculateDiscountIdsAndNumberOfTimesApplied(potentialComboDiscounts);
        }

        if (!isEqual(newComboDiscountIds, comboDiscountIds)) {
            setComboDiscountIds(newComboDiscountIds);
            recalculateOrderWindows();
        }
    }, [potentialComboDiscounts]);

    useEffect(() => {
        if (menus && menus.length > 0) {
            setPossibleUpsellDiscounts(orderWindow);
        }
    }, [menus, orderWindow?.cartProducts, orderWindow?.cartProducts?.length]);

    /**
     * Sets intial combo discounts that are available
     * @param {ComboDiscount[]} comboDiscounts
     */
    const setComboDiscounts = (comboDiscounts: ComboDiscount[]) => {
        comboDiscounts?.sort((combo1: ComboDiscount, combo2: ComboDiscount) =>
            combo1.comboCategories[0].limit < combo2.comboCategories[0].limit ? -1 : 1
        );
        onSetAllComboDiscounts(comboDiscounts);
    };

    const clearDiscounts = () => {
        setPotentialComboDiscounts([]);
    };

    return {
        comboDiscountIds,
        potentialComboDiscounts,
        potentialUpsellDiscounts,
        allComboDiscounts,
        setComboDiscounts,
        clearDiscounts
    };
};
