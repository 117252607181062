import React, { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { useState } from "react";
//TODO: location state
import { NavigateFunction } from "react-router-dom";

import { drawers, DrawerNames } from "./drawers";

interface IDrawerContext {
    drawer: DrawerNames;
    props: any;
    onOpenDrawer: (drawer: DrawerNames, props?: any, routerProps?: RouterProps) => void;
    onCloseDrawer: () => void;
}

type RouterProps = {
    navigate?: NavigateFunction;
};

type DrawerState = {
    drawer: DrawerNames;
    props: any;
    routerProps?: RouterProps;
};

/** In order to use router hooks in drawer you can pass them to it as prop */
export interface IDrawerComponentContext<T extends { [prop: string]: any }> {
    onOpenDrawer: (drawer: DrawerNames, props: T, routerProps?: RouterProps) => void;
    onCloseDrawer: () => void;
    props: T;
    routerProps?: RouterProps;
}

const DrawerContext = createContext<IDrawerContext>(null as any);

const DrawerProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [drawer, setDrawer] = useState<DrawerState>({ drawer: "", props: {} });

    const onOpenDrawer = (drawer: DrawerNames, props: any = {}, routerProps?: RouterProps) => {
        setDrawer({ drawer, props, routerProps });
    };

    const onCloseDrawer = () => {
        setTimeout(() => {
            // When animation is done we can reset the drawer
            setDrawer({
                drawer: "",
                props: {}
            });
        }, 300);
    };


    const contextValue = { onOpenDrawer, onCloseDrawer, ...drawer };

    return <DrawerContext.Provider value={contextValue}>{children}</DrawerContext.Provider>;
};

const DrawerRender = () => (
    <DrawerContext.Consumer>
        {({ drawer, ...state }) => {
            const Component = drawer && drawers[drawer];

            if (Component) {
                return <Component {...(state as any)} />;
            }
        }}
    </DrawerContext.Consumer>
);

const useDrawer = () => {
    const drawerContext = useContext(DrawerContext);
    if (!drawerContext) {
        throw new Error("useDrawer must be within DrawerProvider");
    }
    return drawerContext;
};

export { DrawerProvider, DrawerRender, useDrawer };
