type MessageHeader = {
    $: MessageHeaderRoot;
};

type MessageHeaderRoot = {
    TerminalID: string;
    VersionNumber: string;
};

type Dfs13TransferAmount = {
    TransactionType: string;
    OperId: string;
    HostData?: string;
    Amount1: number;
    Amount2: string;
    Amount3: string;
    Type2: string;
    Type3: string;
    OptionalData: string;
};

type Dfs13Administration = {
    OperId: string;
    AdmCode: string;
    OptionalData: string;
};

type TransferAmountRequest = {
    NetsRequest: {
        MessageHeader: MessageHeader;
        Dfs13TransferAmount: Dfs13TransferAmount;
    };
};

type AdminRequest = {
    NetsRequest: {
        MessageHeader: MessageHeader;
        Dfs13Administration: Dfs13Administration;
    };
};

type OpenRequest = {
    NetsRequest: {
        MessageHeader: MessageHeader;
        Open: Object;
    };
};

/**
 * Purchase message for cloud baxi
 *
 * @param {string} terminalId the id of the terminal to do purchase
 * @param {number} amount the amount in kr
 * @param {string} orderIdentifier a generated unique identifier (uuid) to be able to recognize the transaction
 * @returns {object}
 */
export const baxiPurchase = (terminalId: string, amount: number, orderIdentifier: string): TransferAmountRequest => {
    const amountInDecimals = Math.round(amount * 100);
    return {
        NetsRequest: {
            MessageHeader: {
                $: {
                    TerminalID: terminalId,
                    VersionNumber: "1"
                }
            },
            Dfs13TransferAmount: {
                TransactionType: "48",
                OperId: "0000",
                HostData: orderIdentifier,
                Amount1: amountInDecimals,
                Amount2: "0",
                Amount3: "0",
                Type2: "48",
                Type3: "48",
                OptionalData: ""
            }
        }
    };
};

/**
 * Make reconciliation message for cloud baxi
 *
 * @param {string} terminalId the id of the terminal to do reconciliation
 * @returns {object}
 */
export const baxiReconciliation = (terminalId: string): AdminRequest => {
    return {
        NetsRequest: {
            MessageHeader: {
                $: {
                    TerminalID: terminalId,
                    VersionNumber: "1"
                }
            },
            Dfs13Administration: {
                OperId: "0000",
                AdmCode: "12592",
                OptionalData: ""
            }
        }
    };
};

export const baxiOpenConnection = (terminalId: string): OpenRequest => {
    return {
        NetsRequest: {
            MessageHeader: {
                $: {
                    TerminalID: terminalId,
                    VersionNumber: "1"
                }
            },
            Open: {}
        }
    };
};

export const baxiRefund = (terminalId: string, amount: number): TransferAmountRequest => {
    const amountInDecimals = Math.round(amount * 100);
    return {
        NetsRequest: {
            MessageHeader: {
                $: {
                    TerminalID: terminalId,
                    VersionNumber: "1"
                }
            },
            Dfs13TransferAmount: {
                TransactionType: "49",
                OperId: "4321",
                Amount1: amountInDecimals,
                Amount2: "0",
                Amount3: "0",
                Type2: "48",
                Type3: "48",
                OptionalData: ""
            }
        }
    };
};

export const baxiCancel = (terminalId: string): AdminRequest => {
    return {
        NetsRequest: {
            MessageHeader: {
                $: {
                    TerminalID: terminalId,
                    VersionNumber: "1"
                }
            },
            Dfs13Administration: {
                OperId: "0000",
                AdmCode: "12594",
                OptionalData: ""
            }
        }
    };
};
