import React, { PropsWithChildren, useEffect, useState } from "react";


import { useCookieConsent, useLocalStorage, useTypedMutation, useTypedQuery } from "Hooks";
import { LOCAL_STORAGE_CONSTANTS } from "Constants";
import { AuthenticatedUser, CookieKeys, UserAccount, UserTypes } from "Types";
import { LOGOUT_USER_ACCOUNT_MUTATION } from "GraphQLMutations";
import { FIND_USER_ACCOUNT_BY_HASHED_REFRESH_TOKEN } from "GraphQLQueries";

/** @deprecated - Re-export to support importing these types from "Providers" until completely migrated*/
export type { UserAccount } from "Types";
/** @deprecated - Re-export to support importing these types from "Providers" until completely migrated*/
export { UserTypes };

type CompanyUser = AuthenticatedUser;

type AuthUser = UserAccount | CompanyUser;

export interface IAuthUserContext {
    userAccount: UserAccount | null;
    companyUser: CompanyUser | null;
    logout: (to: string) => void;
    onLogin: (user: AuthenticatedUser) => void;
    logoutUserAccount: () => Promise<void>;
    setUserAccount: (userAccount: UserAccount) => void;
    setCompanyUser: (companyUser: CompanyUser) => void;
    setAuthUserAndToken: (userType: UserTypes, user: UserAccount | CompanyUser) => void;
}

type State = {
    userAccount: UserAccount | null;
    companyUser: CompanyUser | null;
};

export const AuthUserContext = React.createContext<IAuthUserContext | undefined>(undefined);

const initialStateObj = {
    userAccount: null,
    companyUser: null
};

const initialState = (authUser: AuthUser): State => {
    if (!!authUser) {
        if ("companyId" in authUser) {
            return {
                companyUser: authUser,
                userAccount: null
            };
        } else {
            return {
                companyUser: null,
                userAccount: authUser
            };
        }
    } else {
        return initialStateObj;
    }
};

export const AuthUserProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [logoutUserAccount] = useTypedMutation(LOGOUT_USER_ACCOUNT_MUTATION);

    const [localAuthUser, setLocalAuthUser, removeLocalAuthUser] = useLocalStorage(
        LOCAL_STORAGE_CONSTANTS.AUTHENTICATED_USER
    );

    const {
        selectedCookie: jwtRefreshToken,
        removeSelectedCookie: removeJwtRefreshToken,
        removeAccessToken
    } = useCookieConsent(CookieKeys.JWT_REFRESH_TOKEN);

    const [state, setState] = useState<State>(() => initialState(localAuthUser));

    const isInBackOffice = location.pathname.includes("/admin");

    /*
        if we have cookies, but nothing in local storage, we know we logged in from a subdomain
        and are technically logged in. in that case, we want to fetch the logged in user account
        by his jwt refresh token we have in cookies.

        when fetched, we set the user in the state
    */

    const { data } = useTypedQuery(FIND_USER_ACCOUNT_BY_HASHED_REFRESH_TOKEN, {
        variables: {
            refreshToken: jwtRefreshToken
        },
        skip: isInBackOffice
    });

    useEffect(() => {
        const isLoggedInWithCookie = jwtRefreshToken;
        const hasUserAccount = !!state.userAccount;

        const handleLoad = async () => {
            if (data && data.findUserByRefreshToken) {
                setAuthUserAndToken(UserTypes.USER_ACCOUNT, data.findUserByRefreshToken as any);
            }
        };

        if (isLoggedInWithCookie && data) {
            handleLoad();
        }
    }, [jwtRefreshToken, data]);

    const setUserAccount = (userAccount: UserAccount) => {
        setState({
            userAccount,
            companyUser: null
        });
        setLocalAuthUser(userAccount);
    };

    const setCompanyUser = (companyUser: CompanyUser) => {
        setState({
            companyUser,
            userAccount: null
        });
        setLocalAuthUser(companyUser);
    };

    const setAuthUserAndToken = (userType: UserTypes, user: UserAccount | CompanyUser) => {
        setState(currState => ({
            ...currState,
            [userType]: user
        }));
        setLocalAuthUser(user);
    };

    const handleLogoutUserAccount = async () => {
        try {
            await logoutUserAccount();
        } catch (error) {
            console.log(`Couldn't logout: ${error}`);
            removeAccessToken();
        } finally {
            clearLocalLoginState();
        }
    };

    const onLogout = (to: string) => {
        clearLocalLoginState();
       // navigate(to);
    };

    const onLogin = (user: AuthenticatedUser) => {
        if (!!user) {
            if ("companyId" in user) {
                setCompanyUser(user);
            } else {
                setUserAccount(user);
            }
        }
    };

    const clearLocalLoginState = () => {
        setState({ userAccount: null, companyUser: null });
        removeJwtRefreshToken();
        removeLocalAuthUser();
    };

    const value = {
        userAccount: state.userAccount,
        companyUser: state.companyUser,
        logout: onLogout,
        onLogin,
        setUserAccount,
        logoutUserAccount: handleLogoutUserAccount,
        setCompanyUser,
        setAuthUserAndToken
    };

    return <AuthUserContext.Provider value={value}>{children}</AuthUserContext.Provider>;
};

export const useAuthUser = () => {
    const ctx = React.useContext(AuthUserContext);
    if (!ctx) {
        throw new Error("useAuthUser must be within AuthUserProvider");
    }
    return ctx;
};
