import * as React from "react";

export interface ConfirmationOptions {
    catchOnCancel?: boolean;
    component: any;
    modalContent: any;
}

const AwaitModalResponseContext = React.createContext<(options: ConfirmationOptions) => Promise<void>>(Promise.reject);

export const useAwaitModalResponse = () => React.useContext(AwaitModalResponseContext);

// @ts-ignore
export const AwaitModalResponseProvider = ({ children }: React.PropsWithChildren) => {
    const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null);

    const awaitingPromiseRef = React.useRef<{
        resolve: (valueObject: any) => void;
        reject: () => void;
    }>();

    const openConfirmation = (options: ConfirmationOptions) => {
        setConfirmationState(options);
        return new Promise<void>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (confirmationState && confirmationState.catchOnCancel && awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }

        setConfirmationState(null);
    };

    const handleSubmit = (valueObject: any) => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(valueObject);
        }

        setConfirmationState(null);
    };
    const AwaitModal = confirmationState && confirmationState.component;

    // @ts-ignore
    return (
        <>
            <AwaitModalResponseContext.Provider value={openConfirmation} children={children} />

            {confirmationState && (
                <AwaitModal
                    open={Boolean(confirmationState)}
                    onSubmit={handleSubmit}
                    onClose={handleClose}
                    modalContent={confirmationState.modalContent}
                />
            )}
        </>
    );
};
