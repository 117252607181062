import React, { useState, useEffect, PropsWithChildren } from "react";

import { usePos } from "Providers";
import {
    OrderWindow,
    ComboDiscount,
    CartProduct,
    CombinedDiscount,
    UpsellDiscount,
    UpsellDiscountOrderWindow,
    PotentialComboDiscount,
    DISCOUNT_STATE
} from "Types";
import { useCombinedDiscount } from "./utils/useCombinedDiscount";
import { updateAllCartProductsComboDiscount } from "./utils/comboDiscountHelper";
import { useUpsoldDiscounts } from "./utils/useUpsoldDiscounts";
import { useSessionStorage } from "Hooks";

import { useOrderWindowsStore } from "Stores";
export interface ICombinedDiscount {
    potentialUpsellDiscounts: UpsellDiscount[];
    numberOfUpsellsPresent: number;
    clearDiscounts: () => void;
    hasComboDiscounts: boolean;
}

export interface IComboDiscountState {
    setComboDiscountState: (discountState: DISCOUNT_STATE) => void;
    comboDiscountState: DISCOUNT_STATE;
    hasPausedDiscounts: boolean;
}

export interface IPotentialUpsell {
    forcedUpsellChecks: Map<string, boolean>;
    controlSetForcedUpsellCheck: (discountId: string, disabled: boolean) => void;
    getDiscountQuantitiesWithIds: (activeOrderWindowId: string) => Map<string, number>;
    setUpsellDiscount: (discountId: string, productId: string) => void;
    onSplitUpsoldDiscounts: (currentWindowId: string, splitWindowId: string) => void;
}
export interface IDiscountContext {
    combinedDiscount: ICombinedDiscount;
    potentialUpsell: IPotentialUpsell;
    switchCombinedDiscountState: IComboDiscountState;
    getDiscountedOrderWindow: (activeOrderWindowId: string | null) => OrderWindow | undefined;
}

export const DiscountContext = React.createContext<IDiscountContext>(null as any);

export const calculateActiveOrderWindowDiscount = (
    orderWindow: OrderWindow,
    comboDiscountIds: Map<string, number>,
    previousComboDiscountIds: Map<string, number>,
    upsoldDiscounts: UpsellDiscountOrderWindow[],
    potentialComboDiscounts: PotentialComboDiscount[]
) => {
    // We never want orderWindow to be `null` in `orderWindows`
    // Let's just return the unmodified orderWindow here
    let updatedOrderWindow: OrderWindow = orderWindow;
    let hasPreviousComboIds = false;

    if (comboDiscountIds?.size && comboDiscountIds.size > 0 && orderWindow?.cartProducts.length) {
        hasPreviousComboIds = false;
        updatedOrderWindow = calculateComboDiscounts(
            comboDiscountIds,
            upsoldDiscounts,
            orderWindow,
            potentialComboDiscounts
        );
    } else if (previousComboDiscountIds.size > 0 && comboDiscountIds.size === 0) {
        hasPreviousComboIds = true;
        updatedOrderWindow = calculateComboDiscounts(
            comboDiscountIds,
            upsoldDiscounts,
            orderWindow,
            potentialComboDiscounts
        );
    } else if (previousComboDiscountIds.size === 0 && comboDiscountIds.size === 0) {
        if (orderWindow?.cartProducts?.length > 0) {
            updatedOrderWindow = calculateComboDiscounts(
                comboDiscountIds,
                upsoldDiscounts,
                orderWindow,
                potentialComboDiscounts
            );
        } else {
            updatedOrderWindow = {
                ...orderWindow,
                comboDiscounts: [],
                comboDiscountedProductIds: [],
                discountedProductIds: [],
                discount: null
            };
        }
    }

    return {
        updatedOrderWindow,
        hasPreviousComboIds
    };
};

const calculateComboDiscounts = (
    comboDiscountIds: Map<string, number>,
    upsoldDiscounts: UpsellDiscountOrderWindow[],
    orderWindow: OrderWindow,
    potentialComboDiscounts: PotentialComboDiscount[]
) => {
    const discountIdsToApply = Array.from(comboDiscountIds);
    const upsoldOrderWindow =
        upsoldDiscounts?.find((value: UpsellDiscountOrderWindow) => value.orderWindowId === orderWindow.id) ?? null;

    const updatedOrderWindow = updateAllCartProductsComboDiscount(
        orderWindow,
        discountIdsToApply,
        potentialComboDiscounts,
        upsoldOrderWindow
    );
    return updatedOrderWindow;
};

export const DiscountsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const {
        discountUtils: { setActiveOrderWindowDiscount }
    } = usePos();

    const {
        getActiveOrderWindow,
        activeOrderWindowId,
        setActiveOrderWindowId,
        getOrderWindow,
        comboDiscountState,
        setComboDiscountState
    } = useOrderWindowsStore();
    const orderWindow = getActiveOrderWindow();
    const { potentialUpsellDiscounts, setComboDiscounts, allComboDiscounts, clearDiscounts } = useCombinedDiscount();

    const [pausedComboDiscounts, setPausedComboDiscounts, removePausedComboDiscounts] = useSessionStorage(
        [],
        "combinedDiscounts"
    );

    const hasComboDiscounts = allComboDiscounts && allComboDiscounts.length > 0;
    const numberOfUpsellsPresent = potentialUpsellDiscounts.reduce((total: number, value: UpsellDiscount) => {
        return (total += value.potentialApplied?.size ?? 0);
    }, 0);

    const {
        upsoldDiscounts,
        forcedUpsellChecks,
        setForcedUpsellChecks,
        controlSetForcedUpsellCheck,
        setUpsellDiscount,
        setSplitUpsoldDiscounts
    } = useUpsoldDiscounts();

    const getDiscountQuantitiesWithIds = (activeOrderWindowId: string): Map<string, number> => {
        let activeOrderWindow = getDiscountedOrderWindow(activeOrderWindowId);
        if (!activeOrderWindow) {
            return new Map();
        }
        const { cartProducts } = activeOrderWindow;
        return cartProducts?.reduce((discountIds: Map<string, number>, cart: CartProduct) => {
            const { combinedDiscounts } = cart?.orderProduct;
            if (combinedDiscounts) {
                const cartDiscounts = combinedDiscounts.map((discount: CombinedDiscount) => ({
                    discountId: discount.discountId,
                    quantity: discount.quantityUsedForDiscount
                }));
                cartDiscounts.forEach(value => {
                    const { discountId, quantity } = value;
                    const hasDiscountId = discountIds.get(discountId);
                    if (hasDiscountId) {
                        discountIds.set(discountId, hasDiscountId + (quantity ?? 0));
                    } else {
                        discountIds.set(discountId, quantity ?? 0);
                    }
                });
            }
            return discountIds;
        }, new Map());
    };

    const getDiscountedOrderWindow = (orderWindowId: string | null = activeOrderWindowId) => {
        if (allComboDiscounts.length === 0) {
            return getActiveOrderWindow();
        }
        const theDiscountedOrderWindow = getOrderWindow(orderWindowId);

        if (theDiscountedOrderWindow) {
            return theDiscountedOrderWindow;
        } else {
            return getActiveOrderWindow();
        }
    };

    const onSplitUpsoldDiscounts = (activeOrderWindowId: string, splitOrderWindowId: string) => {
        const copyOrderWindow = upsoldDiscounts?.find(
            (value: UpsellDiscountOrderWindow) => value.orderWindowId === activeOrderWindowId
        );
        if (orderWindow) {
            const copyUpsold = {
                orderWindowId: splitOrderWindowId,
                inSplitMode: true,
                upsoldDiscounts: copyOrderWindow?.upsoldDiscounts ?? []
            };
            setSplitUpsoldDiscounts(copyUpsold);
        }
    };

    /** Reset the forced upsell checks on empty cart */
    useEffect(() => {
        if (!orderWindow?.cartProducts?.length && hasComboDiscounts) {
            setForcedUpsellChecks(new Map());
        }
    }, [orderWindow?.cartProducts]);

    /** Turning off combo discounts
     * - I have 3 states None, on, off - the default is none as I don't want to effect
     * the discounts in anyway unless the button is clicked and just having true and false
     * could create unnecessary renders
     */
    useEffect(() => {
        switch (comboDiscountState) {
            case DISCOUNT_STATE.OFF: {
                setPausedComboDiscounts(allComboDiscounts);
                setComboDiscounts([]);
                break;
            }
            case DISCOUNT_STATE.ON: {
                setComboDiscounts(pausedComboDiscounts);
                removePausedComboDiscounts();
                break;
            }
            case DISCOUNT_STATE.NONE: {
                removePausedComboDiscounts();
            }
        }
    }, [comboDiscountState]);

    const value = {
        combinedDiscount: {
            setComboDiscounts,
            clearDiscounts,
            numberOfUpsellsPresent,
            potentialUpsellDiscounts,
            hasComboDiscounts
        },
        potentialUpsell: {
            forcedUpsellChecks,
            controlSetForcedUpsellCheck,
            getDiscountQuantitiesWithIds,
            setUpsellDiscount,
            onSplitUpsoldDiscounts
        },
        switchCombinedDiscountState: {
            setComboDiscountState,
            comboDiscountState,
            hasPausedDiscounts: pausedComboDiscounts && pausedComboDiscounts.length > 0
        },
        getDiscountedOrderWindow
    };

    return <DiscountContext.Provider value={value}>{children}</DiscountContext.Provider>;
};

export const DiscountConsumer = DiscountContext.Consumer;

export const useDiscounts = () => {
    const ctx = React.useContext(DiscountContext);
    if (!ctx) {
        throw new Error("useDiscounts must be within DiscountContext");
    }
    return ctx;
};
