import { cloneDeep } from "lodash";

import {
    OrderWindow,
    CartProduct,
    PotentialComboDiscount,
    SelectedCartProduct,
    CombinedDiscount,
    DISCOUNT_TYPE,
    UpsellDiscountOrderWindow,
    DiscountUpsold
} from "Types";
import {
    calculateVatAmount,
    getCartProductOrgUnitPrice,
    getRefVatRate,
    removeDiscountAndResetPrice,
    calculateTotalAddonPrices
} from "../../../admin/components/poses/pos/utils";
import { round } from "lodash";

/** update All cart Products with combo discount "ENTRY POINT"
 * @param {OrderWindow} orderWindow current order window with cart products
 * @param {Array<[string, number]>} comboDiscountIds pre calculate discount id with number of times it can be implemented "IN ORDER"
 * @param {PotentialComboDiscount[]} comboDiscounts All current combined discounts that could be applied
 * @return {OrderWindow} current order window with discounted cart products
 */
export const updateAllCartProductsComboDiscount = (
    orderWindow: OrderWindow | undefined,
    comboDiscountIds: Array<[string, number]>,
    comboDiscounts: PotentialComboDiscount[],
    upsoldComboDiscounts: UpsellDiscountOrderWindow | null
): OrderWindow => {
    let freezedDiscounts = cloneDeep(comboDiscounts);
    let onGoingComboDiscount: PotentialComboDiscount[] = freezedDiscounts;
    let alreadyDiscountedIds = new Set<string>();

    const newUpdatedOrderWindow = {
        ...orderWindow,
        cartProducts: removeComboCombinedDiscounts(orderWindow?.cartProducts || []) ?? []
    };

    if (comboDiscountIds.length > 0) {
        let cartProductsDiscounted: CartProduct[] = comboDiscountIds.reduce(
            (theCartProducts: CartProduct[], comboDiscountId: [string, number]) => {
                const [discountId, noOfTimesToApply] = comboDiscountId;
                const comboDiscount = onGoingComboDiscount.find(
                    (combo: PotentialComboDiscount) => combo.id === discountId
                );
                const upsoldDiscounts = upsoldComboDiscounts?.upsoldDiscounts?.find(
                    (value: DiscountUpsold) => value.discountId === discountId
                );
                if (comboDiscount) {
                    const cartProductsWithComboDiscount = applyComboDiscounts(
                        theCartProducts,
                        comboDiscount,
                        noOfTimesToApply,
                        upsoldDiscounts
                    );
                    onGoingComboDiscount = updateSelectedProductQuantities(
                        onGoingComboDiscount,
                        comboDiscount,
                        alreadyDiscountedIds
                    );
                    alreadyDiscountedIds.add(comboDiscount.id!);
                    return cartProductsWithComboDiscount;
                }
                return theCartProducts;
            },
            newUpdatedOrderWindow?.cartProducts
        );

        return {
            ...newUpdatedOrderWindow,
            cartProducts: cartProductsDiscounted
        } as OrderWindow;
    } else {
        return newUpdatedOrderWindow as OrderWindow;
    }
};

/**
 * Reset combo cart products discount
 * @param {CartProduct[]} cartProducts
 * @returns cleaned cart products with no combo discount
 */
const removeComboCombinedDiscounts = (cartProducts: CartProduct[]) => {
    return cartProducts.map((cart: CartProduct) => {
        if (!cart.orderProduct?.combinedDiscounts) {
            return cart;
        } else {
            const { orderProduct } = cart;
            const hasComboDiscounts = orderProduct.combinedDiscounts?.some(
                (discount: CombinedDiscount) => discount.discountType === DISCOUNT_TYPE.COMBO_DISCOUNT
            );
            if (hasComboDiscounts) {
                return removeDiscountAndResetPrice(cart);
            } else {
                return cart;
            }
        }
    });
};

/**
 * return all cart ids from order window that have combo discounts
 * @param {orderWindow} orderWindow updated order window
 */
export const getCombinedDiscountedProductIds = (orderWindow: OrderWindow) => {
    return orderWindow?.cartProducts?.reduce((cartIds: string[], cart: CartProduct) => {
        const { combinedDiscounts } = cart.orderProduct;
        const hasComboDiscounts =
            combinedDiscounts?.some(
                (discount: CombinedDiscount) => discount.discountType === DISCOUNT_TYPE.COMBO_DISCOUNT
            ) ?? false;
        if (hasComboDiscounts) {
            return [...cartIds, cart.id];
        }
        return cartIds;
    }, []);
};

/**
 * Each Potential Discount has a quantity on the discount under selectedCartProducts this function will update any other Cart Product on other discounts
 * in order to discount the correct cart ids with the correct quantity
 * @param {PotentialComboDiscount[]} onGoingComboDiscount Current Potential discounts
 * @param {PotentialComboDiscount} currentComboDiscount Current Combo Discount Used
 * @param {Set<string>} alreadyDiscountedIds Discount ids that have been already done
 * @returns
 */
export const updateSelectedProductQuantities = (
    onGoingComboDiscounts: PotentialComboDiscount[],
    currentComboDiscount: PotentialComboDiscount,
    alreadyDiscountedIds: Set<string>
) => {
    return onGoingComboDiscounts.map((discount: PotentialComboDiscount) => {
        if (!alreadyDiscountedIds.has(discount.id!)) {
            const { selectedCartProducts } = discount;
            const currentDiscountCartSelectedProducts = currentComboDiscount.selectedCartProducts;
            const selectedProductQuanityChange = selectedCartProducts?.map((select: SelectedCartProduct) => {
                const hasProductInDiscounts = currentDiscountCartSelectedProducts?.find(
                    (currentSelect: SelectedCartProduct) => currentSelect.cartId === select.cartId
                );
                if (hasProductInDiscounts) {
                    return {
                        ...select,
                        currentQuantity: hasProductInDiscounts.currentQuantity
                    };
                } else {
                    return select;
                }
            });
            return {
                ...discount,
                selectedCartProducts: selectedProductQuanityChange
            };
        } else {
            return discount;
        }
    });
};

/**
 * Remove applied discount from a cart product that will have a combo discount applied
 * @param {CartProduct} cartProduct
 */
const removeAppliedDiscount = (cartProduct: CartProduct) => {
    cartProduct.orderProduct.combinedDiscounts = cartProduct.orderProduct?.combinedDiscounts?.filter(
        (select: CombinedDiscount) => {
            return select.discountType !== DISCOUNT_TYPE.APPLIED_DISCOUNT;
        }
    );
};

/**
 * Apply individual discounts to the cart products
 * @param {CartProduct[]} cartProducts current cart products that have potential discount
 * @param {PotentialComboDiscount} comboDiscount discount to use on the cart
 * @param {number} appliedNoOfTimes how many times to apply the discount
 * @returns {CartProduct[]} discounted cart Products
 */
export const applyComboDiscounts = (
    cartProducts: CartProduct[],
    comboDiscount: PotentialComboDiscount,
    appliedNoOfTimes: number,
    upsoldDiscount?: DiscountUpsold
): CartProduct[] => {
    const quantityForEachAppliedDiscount = comboDiscount.comboCategories[0].limit;
    const numberOfTimesDiscountIsApplied = [...Array(appliedNoOfTimes)];

    numberOfTimesDiscountIsApplied.forEach((_, index: number) => {
        /** Here get cart ids and quantity */
        const [howToApplySingleDiscount, quantityLeft] = howToApplyDiscountByCartIdAndQuantity(comboDiscount);

        /** Calculation to total price to discounted by using above const  */
        const discountedTotalPrice = getTotalDiscountedPrice(howToApplySingleDiscount, cartProducts);

        /** Get discount value from the discounted total price minus the discount static price for that quantity */
        const totalDiscountValue = discountedTotalPrice - comboDiscount.staticPrice;

        let comboUpsellValue = 0;

        /** Get upsold value if present */
        if (upsoldDiscount) {
            /** Price of product added to make discount
             *  Also if the discount is applied 2 but the upsell is on one
             *  there will be one productId in the productIds array
             */
            const productId = upsoldDiscount.productIds[index];

            if (productId) {
                const addedPriceToCart = getSinglePriceOfProduct(cartProducts, productId);
                /** total price of cart product in discount minus the cart product added to give previous price */
                const valueBeforeDiscountedPrice = discountedTotalPrice - addedPriceToCart;

                /** If the static price is large than the value before discount then they should be a upsell discount value */
                const hasComboUpsoldDiscount = comboDiscount.staticPrice > valueBeforeDiscountedPrice;
                if (hasComboUpsoldDiscount) {
                    /** Upsell price value difference between discounted price and value before discount
                     * E.G
                     * 3 buns for 110kr - discount
                     * 40 x 3 which is 120 kr (normal price)
                     * for 2 = 80kr
                     * upsell value is 110kr - 80kr which is 30kr
                     */
                    comboUpsellValue = comboDiscount.staticPrice - valueBeforeDiscountedPrice;
                }
            }
        }

        howToApplySingleDiscount.forEach((appliedQuantity: number, cartId: string) => {
            cartProducts.forEach((cartProduct: CartProduct) => {
                if (cartProduct.id === cartId) {
                    const {
                        orderProduct: { quantity }
                    } = cartProduct!;

                    const hasAppliedDiscount =
                        cartProduct.orderProduct.combinedDiscounts?.some(
                            (discount: CombinedDiscount) => discount.discountType === DISCOUNT_TYPE.APPLIED_DISCOUNT
                        ) ?? false;
                    if (hasAppliedDiscount) {
                        removeAppliedDiscount(cartProduct);
                        cartProduct.orderProduct.discountValue = 0;
                    }

                    let orgUnitPrice = getCartProductOrgUnitPrice(cartProduct!);

                    // Calculate add on prices and remove from org unit price
                    let addonsPrice = calculateTotalAddonPrices(cartProduct.orderProduct!);
                    orgUnitPrice = orgUnitPrice - addonsPrice;

                    const updatedOriginalPrice = orgUnitPrice * quantity;

                    // first get the cart product discount value - if it isn't there start from 0
                    let cartProductDiscountValue = cartProduct.orderProduct.discountValue ?? 0;

                    // Discount ratio according to quantity - so 1 product in 3 would be 1/3 or 0.3333
                    const discountRatio = getDiscountedRatio(appliedQuantity, quantityForEachAppliedDiscount);

                    let cartValueOnUpsell = 0;
                    if (comboUpsellValue > 0) {
                        /** can use the discount ratio for combo upsell value as it based on quantity */
                        cartValueOnUpsell = comboUpsellValue * discountRatio;
                    }

                    // Calculate the discount for this cart Product based on the total discount value and the discount ratio
                    const cartDiscountValue = totalDiscountValue * discountRatio;

                    // Add togther discounts
                    cartProductDiscountValue += cartDiscountValue;

                    // Get updated Price
                    let updatedTotalPrice = updatedOriginalPrice - cartProductDiscountValue;
                    if (updatedOriginalPrice - cartProductDiscountValue <= 0) {
                        updatedTotalPrice = 0;
                        cartProductDiscountValue = updatedOriginalPrice;
                    }
                    updatedTotalPrice += addonsPrice;
                    // calculate vat and net price
                    const vatRate = getRefVatRate(cartProduct) ?? 0;
                    const updatedVatAmount = calculateVatAmount(updatedTotalPrice, vatRate);
                    const updatedTotalNetPrice = updatedTotalPrice - updatedVatAmount;

                    // discount rate per unit
                    const totalDiscountRatePerUnit = cartProductDiscountValue / quantity;

                    // updated Total price per unit
                    const updatedTotalPerUnitPrice = orgUnitPrice - totalDiscountRatePerUnit;

                    // Main discount rate on order product
                    const fullDiscountRate = round(cartProductDiscountValue / updatedOriginalPrice, 5);

                    const discountOrder = cartProduct.orderProduct?.combinedDiscounts
                        ? cartProduct.orderProduct?.combinedDiscounts.length + 1
                        : 1;

                    const combinedDiscount: CombinedDiscount = {
                        discountId: comboDiscount.id!,
                        name: comboDiscount.name,
                        discountValue: cartDiscountValue,
                        discountedFrom: discountedTotalPrice,
                        discountRate: round(cartDiscountValue / discountedTotalPrice, 5),
                        discountOrder: discountOrder,
                        quantityUsedForDiscount: appliedQuantity,
                        comboUpsellValue: cartValueOnUpsell,
                        discountType: DISCOUNT_TYPE.COMBO_DISCOUNT
                    };

                    if (cartProduct.orderProduct.combinedDiscounts?.length) {
                        cartProduct.orderProduct = {
                            ...cartProduct.orderProduct,
                            discountValue: cartProductDiscountValue,
                            totalPrice: updatedTotalPrice,
                            totalNetPrice: updatedTotalNetPrice,
                            unitPrice: updatedTotalPerUnitPrice,
                            discountRate: fullDiscountRate,
                            vatRate: vatRate,
                            combinedDiscounts: [...cartProduct.orderProduct.combinedDiscounts!, combinedDiscount]
                        };
                    } else {
                        cartProduct.orderProduct = {
                            ...cartProduct.orderProduct,
                            discountValue: cartProductDiscountValue,
                            totalPrice: updatedTotalPrice,
                            totalNetPrice: updatedTotalNetPrice,
                            unitPrice: updatedTotalPerUnitPrice,
                            discountRate: fullDiscountRate,
                            vatRate: vatRate,
                            combinedDiscounts: [combinedDiscount]
                        };
                    }
                }
            });
        });
    });
    return cartProducts;
};

/** Get Mapped values cart ids and number of times it is used in the current discount
 * @param {PotentialComboDiscount} comboDiscount discount to  use
 * @return {Map<string, number>} values for one applied discount
 */
export const howToApplyDiscountByCartIdAndQuantity = (
    comboDiscount: PotentialComboDiscount
): [Map<string, number>, number] => {
    const limitPerApplication = comboDiscount.comboCategories[0].limit;
    const cartIds: Map<string, number> = new Map();

    const quantityLeft = comboDiscount.selectedCartProducts?.reduce(
        (totalValue: number, selected: SelectedCartProduct) => {
            const cartIdToApply = cartIds.get(selected.cartId);

            if (totalValue === 0 || selected.currentQuantity === 0) {
                return totalValue;
            }

            if (!cartIdToApply && totalValue !== 0) {
                cartIds.set(selected.cartId, 0);
            }
            if (selected.currentQuantity >= totalValue) {
                const valueLeft = totalValue;
                selected.currentQuantity -= valueLeft;
                totalValue -= totalValue;
                const numberOfTimesApplied = (cartIdToApply ? cartIdToApply : 0) + valueLeft;
                cartIds.set(selected.cartId, numberOfTimesApplied);
            } else {
                const productQuantityLeft = selected.currentQuantity;
                selected.currentQuantity -= selected.currentQuantity;
                totalValue -= productQuantityLeft;
                const numberOfTimesApplied = (cartIdToApply ? cartIdToApply : 0) + productQuantityLeft;
                cartIds.set(selected.cartId, numberOfTimesApplied);
            }

            return totalValue;
        },
        limitPerApplication
    );

    return [cartIds, quantityLeft!];
};

/**
 * Get Total Discounted Price from cart Products by id and Quantity of those products in the discount
 * @param {Map<string, number>} idsAndQuantity cartids and quantity
 * @param {CartProduct[]} cartProducts current cart Products
 * @returns {number} Total Price
 */
export const getTotalDiscountedPrice = (idsAndQuantity: Map<string, number>, cartProducts: CartProduct[]) => {
    return [...Array.from(idsAndQuantity)].reduce((total: number, idAndQuantity: [string, number]) => {
        const [cartId, quantity] = idAndQuantity;
        const findCarProduct = cartProducts?.find((cart: CartProduct) => cart.id === cartId);
        if (findCarProduct) {
            const totalValueWithQuantity = priceOfProductAndQuantity(findCarProduct!, quantity);
            return (total += totalValueWithQuantity);
        }
        return total;
    }, 0);
};

/**
 * Function to return the single ( quantity = 1) price of a product added to create the discounts
 * @param {CartProduct[]} cartProducts current cart Products
 * @param {string} productIdAdded current product id added to discounts
 * @returns {[string, number]} single map with cart id and price
 */
export const getSinglePriceOfProduct = (cartProducts: CartProduct[], productIdAdded: string) => {
    return cartProducts.reduce((total: number, cartProduct: CartProduct) => {
        const { orderProduct } = cartProduct;
        const productId = orderProduct.refProductId || orderProduct.refBundleProductId;
        if (productId === productIdAdded) {
            const price = priceOfProductAndQuantity(cartProduct, 1);
            total = price;
        }
        return total;
    }, 0);
};

/**
 * Get Discount Ratio from quantity to be discounted to applied discount quantity
 * @param {number} quantity quantity to be discounted
 * @param discountQuantity quantity limit for when the discount kicks in
 * @returns {number} discount ratio to calculate discount for cart product
 */
const getDiscountedRatio = (quantity: number, discountQuantity: number) => {
    return quantity / discountQuantity;
};

/**
 * Get Base Price of product and find total by using quantity
 * @param {CartProduct} cartProduct single cart product
 * @param {number} quantity number of products to be calculated
 * @returns {number} total base price
 */
const priceOfProductAndQuantity = (cartProduct: CartProduct, quantity: number) => {
    const orgBasePrice = getCartProductOrgUnitPrice(cartProduct);
    const addonPrice = calculateTotalAddonPrices(cartProduct.orderProduct);
    let orgPriceNoAddons = (orgBasePrice - addonPrice) * quantity;

    return orgPriceNoAddons;
};

/**
 * Count how many times each cart id appears in a potential discount
 * @param {PotentialComboDiscount[]} cartToComboDiscounts
 * @param {string} cartId
 * @returns {number} how many discounts the cart product appears in other discounts
 */
export const countCartIdsInDiscounts = (cartToComboDiscounts: PotentialComboDiscount[], cartId: string): number => {
    return cartToComboDiscounts.reduce((total: number, cart: PotentialComboDiscount) => {
        const cartIdIsPresent =
            cart?.selectedCartProducts?.some((selected: SelectedCartProduct) => selected.cartId === cartId) ?? false;
        if (cartIdIsPresent) {
            return (total += 1);
        }
        return total;
    }, 0);
};
