import React, { PropsWithChildren, useState } from "react";

import { LOCAL_STORAGE_CONSTANTS } from "Constants";
import { useEffectOnce, useLocalStorage } from "Hooks";
import { LockableParts, PreferredPuckTab } from "Types";

interface ILocalPosSettings {
    enableManualCardPayment: boolean;
    optionalReceipts: boolean;
    preferredPuckTab: PreferredPuckTab;
    enableOpenProduct: boolean;
    customerFacingOptions: ICustomerFacing;
    enableOrderQueue: boolean;
    lockableParts?: LockableParts[];
    accessCode?: string;
}
export interface ICustomerFacing {
    enableCustomerFacing: boolean;
    allowCustomerFacing: boolean;
}
interface ISetPosSettings {
    setEnableManualCardPayment: React.Dispatch<React.SetStateAction<boolean>>;
    setEnableOpenProduct: React.Dispatch<React.SetStateAction<boolean>>;
    setPreferredPuckTab: React.Dispatch<React.SetStateAction<PreferredPuckTab>>;
    setOptionalReceipts: React.Dispatch<React.SetStateAction<boolean>>;
    onSetEnableCustomerFacing: (enableCustomerFacing: boolean) => void;
    setEnableOrderQueue: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IPosSettings {
    posSettings: ILocalPosSettings;
    setPosSettings: ISetPosSettings;
    setLocalPosSettings: React.Dispatch<React.SetStateAction<ILocalPosSettings>>;
    onSetOptionsForLockableParts: (lockableParts: LockableParts[], lockableAccessCode?: string | undefined) => void;
    disableOnLockingPos: boolean;
}

export const PosSettingsContext = React.createContext<IPosSettings>(null as any);

export const PosSettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const defaultCustomerFacingOptions: ICustomerFacing = {
        enableCustomerFacing: false,
        allowCustomerFacing: false
    };

    const [localPosSettings, setLocalPosSettings] = useLocalStorage(LOCAL_STORAGE_CONSTANTS.POS_SETTINGS, {
        enableManualCardPayment: false,
        optionalReceipts: false,
        preferredPuckTab: PreferredPuckTab.NUM_PAD,
        enableOpenProduct: true,
        customerFacingOptions: defaultCustomerFacingOptions,
        enableOrderQueue: false,
        accessCode: "",
        lockableParts: []
    } as ILocalPosSettings);

    const defaultOrLocalPosSettings = {
        enableManualCardPayment: localPosSettings?.enableManualCardPayment ?? false,
        optionalReceipts: localPosSettings?.optionalReceipts ?? false,
        preferredPuckTab: localPosSettings?.preferredPuckTab ?? PreferredPuckTab.NUM_PAD,
        enableOpenProduct: localPosSettings?.enableOpenProduct ?? false,
        customerFacingOptions: localPosSettings?.customerFacingOptions ?? defaultCustomerFacingOptions,
        enableOrderQueue: localPosSettings?.enableOrderQueue ?? false,
        accessCode: localPosSettings?.accessCode ?? "",
        lockableParts: localPosSettings?.lockableParts ?? []
    };

    /** State Added here is from local settings, checks for default values in storage if not adds a default
     * Prevents already set up from crashing
     */
    const [enableManualCardPayment, setEnableManualCardPayment] = useState(
        defaultOrLocalPosSettings.enableManualCardPayment
    );
    const [enableOpenProduct, setEnableOpenProduct] = useState(defaultOrLocalPosSettings.enableOpenProduct);
    const [preferredPuckTab, setPreferredPuckTab] = useState(defaultOrLocalPosSettings.preferredPuckTab);
    const [optionalReceipts, setOptionalReceipts] = useState(defaultOrLocalPosSettings.optionalReceipts);
    const [customerFacingOptions, setCustomerFacingOptions] = useState<ICustomerFacing>(
        defaultOrLocalPosSettings.customerFacingOptions
    );
    const [accessCode, setAccessCode] = useState(defaultOrLocalPosSettings.accessCode);
    const [lockableParts, setLockableParts] = useState(defaultOrLocalPosSettings.lockableParts);
    const [enableOrderQueue, setEnableOrderQueue] = useState(defaultOrLocalPosSettings.enableOrderQueue);

    /** Originally no default check if local settings were already there
     * This makes sure on a refresh that the defaults are checked for a pos already set up
     * if defaults are missing the local state is updated with the defaults
     */
    const onCheckDefaultPosSettings = () => {
        const containsAllProps =
            localPosSettings &&
            Object.keys(defaultOrLocalPosSettings).every(
                (key: string) => Object.getOwnPropertyNames(localPosSettings).includes(key) ?? false
            );
        !containsAllProps && setLocalPosSettings(defaultOrLocalPosSettings);
    };

    /**
     * [FUNCTION] add lockable parts to local state inc customer facing options + access code
     * @param {LockableParts[]} lockableParts - selection of lockable parts
     * @param {string} lockableAccessCode - access code
     */
    const onSetOptionsForLockableParts = (lockableParts: LockableParts[], lockableAccessCode?: string | undefined) => {
        const isCustomerFacing = lockableParts.includes(LockableParts.CUSTOMER_FACING);
        const customerFacing: ICustomerFacing = {
            enableCustomerFacing: isCustomerFacing ? customerFacingOptions.enableCustomerFacing : false,
            allowCustomerFacing: isCustomerFacing
        };
        setCustomerFacingOptions(customerFacing);
        setAccessCode(lockableAccessCode ?? "");
        setLockableParts(lockableParts);
        setLocalPosSettings({
            ...localPosSettings,
            customerFacingOptions: customerFacing,
            accessCode: !!lockableAccessCode ? lockableAccessCode : "",
            lockableParts: lockableParts
        });
    };

    const onSetEnableCustomerFacing = (enableCustomerFacing: boolean) => {
        const currentCustomerFacing = {
            ...customerFacingOptions,
            enableCustomerFacing: enableCustomerFacing
        };
        setCustomerFacingOptions(currentCustomerFacing);
        setLocalPosSettings({
            ...localPosSettings,
            customerFacingOptions: currentCustomerFacing
        });
    };

    useEffectOnce(() => {
        onCheckDefaultPosSettings();
    });

    const value = {
        posSettings: {
            enableManualCardPayment,
            optionalReceipts,
            preferredPuckTab,
            enableOpenProduct,
            customerFacingOptions,
            enableOrderQueue,
            accessCode,
            lockableParts
        },
        setPosSettings: {
            setEnableManualCardPayment,
            setEnableOpenProduct,
            setPreferredPuckTab,
            setOptionalReceipts,
            onSetEnableCustomerFacing,
            setEnableOrderQueue
        },
        setLocalPosSettings,
        onSetOptionsForLockableParts,
        disableOnLockingPos: customerFacingOptions.enableCustomerFacing
    };

    return <PosSettingsContext.Provider value={value}>{children}</PosSettingsContext.Provider>;
};

export const PosSettingsConsumer = PosSettingsContext.Consumer;

export const useLocalPosSettings = (isSetOnBackOfficeNav?: boolean) => {
    const ctx = React.useContext(PosSettingsContext);
    if (!ctx && !isSetOnBackOfficeNav) {
        throw new Error("useLocalPosSettings must be within PosSettingContext");
    }
    return ctx;
};
