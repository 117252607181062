import { CustomerDrawer } from "../../customer/shared";
import { UserAccountSubscriptionDrawer } from "../../customer/pages/userAccount/components/UserAccountSubscriptions/components";
import { CompanySubscriptionAuthDrawer } from "../../customer/pages/companySubscriptions/components/CompanySubscriptionAuthDrawer";
import { UserAccountGiftCardDrawer } from "../../customer/pages/userAccount/components/UserAccountGiftCards/UserAcountGiftCardDrawer";
import { AddToHomeScreenDrawer } from "../../customer/pages/userAccount/components/AddToHomeScreenDrawer";
import { ProductFeedbackDrawer } from "../../admin/components/customerFeedback/components/feedbackSection/ProductFeedbackDrawer";
import { UserSubscriptionDrawer } from "../../admin/components/subscriptions/overview/drawers/UserSubscriptionDrawer";
import { UserSubscriptionOrdersDrawer } from "../../admin/components/subscriptions/overview/drawers/UserSubscriptionOrdersDrawer";
import { MenuLogDrawer } from "../../admin/components/menu/MenuLogs/MenuLogDrawer";
import { SIEAccountDrawer } from "../../admin/components/sie-account/components/SIEAccountDrawer";
import { QoplaCommentDrawer } from "../../admin/components/shops/components/QoplaCommentDrawer";
import { ImageCropDrawer } from "../../admin/components/mediaLibrary/ImageCropDrawer";
import { CampaignStatisticDrawer } from "../../admin/components/campaignAdmin/components/CampaignStatisticDrawer";
import { ExpressProductInformationDrawer } from "../../admin/components/express-pos-v2/components/product/ExpressProductInformationDrawer";
import { ExpressProductAddonsDrawer } from "../../admin/components/express-pos-v2/views/ExpressProductAddonsDrawer";

export type DrawerNames =
    | ""
    | "customerDrawer"
    | "userAccountSubscriptionDrawer"
    | "companySubscriptionAuthDrawer"
    | "userAccountGiftCardDrawer"
    | "addToHomeScreenDrawer"
    | "productFeedbackDrawer"
    | "userSubscriptionDrawer"
    | "userSubscriptionOrdersDrawer"
    | "menuLogDrawer"
    | "sieAccountDrawer"
    | "qoplaCommentDrawer"
    | "imageCropDrawer"
    | "campaignStatistics"
    | "expressProductInformationDrawer"
    | "expressProductAddonsDrawer";

export const drawers = {
    "": null,
    customerDrawer: CustomerDrawer,
    userAccountSubscriptionDrawer: UserAccountSubscriptionDrawer,
    companySubscriptionAuthDrawer: CompanySubscriptionAuthDrawer,
    userAccountGiftCardDrawer: UserAccountGiftCardDrawer,
    addToHomeScreenDrawer: AddToHomeScreenDrawer,
    productFeedbackDrawer: ProductFeedbackDrawer,
    userSubscriptionDrawer: UserSubscriptionDrawer,
    userSubscriptionOrdersDrawer: UserSubscriptionOrdersDrawer,
    menuLogDrawer: MenuLogDrawer,
    sieAccountDrawer: SIEAccountDrawer,
    qoplaCommentDrawer: QoplaCommentDrawer,
    imageCropDrawer: ImageCropDrawer,
    campaignStatistics: CampaignStatisticDrawer,
    expressProductInformationDrawer: ExpressProductInformationDrawer,
    expressProductAddonsDrawer: ExpressProductAddonsDrawer
};
